#sidebar-scrollbar {
  background-color: #fff9f7;
  overflow-y: auto;
  scrollbar-color: $secondary rgba($secondary, 0.2);
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background-color: $secondary;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba($secondary, 0.2);
  }
  height: 90%;
}

.iq-sidebar {
  // box-shadow: $box-shadow;
  border: 1px solid rgba(187, 28, 23, 0.1);
  background: #fff;
  // position: fixed;
  // z-index: $zindex-dropdown;
  // top: 4.688rem;
  width: 255px;
  // height: 100%;
  // height: 800px;
  background: $white;
  @if $enable-transitions == true {
    @include transition-mixin(all 20s ease-in-out);
  }
  @if $enable-shadows == true {
    @include shadow-mixin($box-shadow);
  }
  .iq-sidebar-logo {
    float: none;
    width: 100%;
  }
  #sidebar-scrollbar {
    margin-top: 0.938rem;
    // height: 100vh;
  }

  .logo {
    margin: 0 0.938rem;
    padding: 0;
    padding-top: 10px;
  }

  .dropdown,
  .dropdown.show {
    width: 100%;
    background-color: #fff;
    height: 90px;
    color: #2e2e2e !important;
    box-shadow: none !important;

    .btn-check:checked + .btn-primary,
    .btn-check:active + .btn-primary,
    .btn-primary:active,
    .btn-primary.active,
    .show > .btn-primary.dropdown-toggle {
      background: none !important;
      border: none !important;
      color: #2e2e2e !important;
    }

    button {
      background: none;
      border: none;
      color: #2e2e2e;
      box-shadow: none;

      &:focus,
      &:active,
      &:hover {
        background: none;
        border: none;
        color: #2e2e2e;
        box-shadow: none !important;
      }
    }

    .profile-container {
      .profile-picture {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .user-name {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .dropdown-menu {
      width: 250px;
      height: 400px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
      inset: auto auto 3.5px 18px !important;
      .dropdown-item {
        width: 250px;
        height: 56px;
        padding: 16px 24px;
        gap: 24px;
        color: #2e2e2e;
        margin-bottom: 10px;

        &:hover {
          background: rgba(246, 214, 202, 0.5);
        }
      }

      .dropdown-item.active {
        background: rgba(246, 214, 202, 0.5);
      }
    }
    .download-container {
      width: 100%;
      height: 66px;
      background: #fff9f7;

      .download-app {
        width: 200px;
        height: 50px;
        padding: 21px 45px;
        border-radius: 10px;
        background: #efb06c;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.points-container {
  border-radius: 10px;
  background: rgba(76, 175, 80, 0.2);
  width: 225px;
  height: 117px;
  margin-top: 15px;

  .points {
    width: 88px;
    height: 87.772px;
    background-color: #fff;
    border-radius: 50%;
    color: #4caf50;
    font-family: Poppins;

    // @media (max-width: 1000px) {
    //   width: 50px;
    //   height: 50px;

    //   .point {
    //     font-size: 16px;
    //   }

    //   small {
    //     display: none;
    //   }
    // }
  }

  .point {
    font-size: 20px;
    letter-spacing: 0.222px;
  }

  small {
    font-size: 10px;
  }

  // @media (max-width: 1000px) {
  //   width: 100%;
  //   height: fit-content;
  //   border-radius: 0;

  //   background: transparent;
  // }
}

.scroll-content {
  div.p-3 {
    height: 100px;
  }
}
.iq-sidebar-menu {
  .iq-menu {
    .iq-menu-title {
      display: block;
      color: $body-color;
      font-size: 1rem;
      position: relative;
      padding: 0.938rem;
      font-weight: 500;
      line-height: 1.125rem;
      i {
        display: none;
      }
    }
    // margin: 0 0.938rem;
    padding: 0;
    list-style-type: none;
    li {
      list-style-type: none;
      position: relative;
      white-space: nowrap;

      // @if $enable-rounded == true {
      //   @include radius-mixin($border-radius);
      // }
      a {
        margin: 0 0.938rem;
        font-size: $font-size-base;
        white-space: nowrap;
        display: block;
        color: $body-color;
        position: relative;
        padding: 0.938rem;
        line-height: 1.125rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        // @if $enable-rounded == true {
        //   @include radius-mixin($border-radius);
        // }
        i {
          font-size: 1.25rem;
          margin-right: 0.938rem;
          vertical-align: middle;
          width: 20px;
          display: inline-block;
        }
        span {
          white-space: nowrap;
          padding: 0;
          display: inline-block;
          @if $enable-transitions == true {
            @include transition-mixin(all 0.3s ease-in-out);
          }
        }
        .iq-arrow-right {
          margin-right: 0;
          margin-left: auto;
          @if $enable-transitions == true {
            @include transition-mixin(all 0.3s ease-in-out);
          }
        }
        .badge {
          position: absolute;
          right: 0.938rem;
          @if $enable-transitions == true {
            @include transition-mixin(all 0.3s ease-in-out);
          }
        }
        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
      li {
        a {
          font-size: $font-size-base;
          i {
            font-size: 1rem;
          }
        }
      }
      a[aria-expanded="true"] {
        color: $primary;
        text-decoration: none;
        @if $enable-rounded == true {
          @include radius-mixin(5px 5px 0 0);
        }
        transition: none;
        .iq-arrow-right {
          @include transform-mixin(rotate(90deg));
          @if $enable-transitions == true {
            @include transition-mixin(all 0.3s ease-in-out);
          }
        }
      }
      .iq-submenu {
        li {
          a {
            &:hover {
              background: transparent;
            }
          }
          > a {
            &::before {
              display: none;
            }
          }
          a[aria-expanded="true"] {
            box-shadow: none;
          }
        }
      }
      ul {
        padding-left: 0rem;
        li {
          a {
            color: $body-color;
            padding: 0.938rem 1.25rem;
          }
        }
        a {
          &:hover {
            color: $primary;
            text-decoration: none;
            background: transparent;
          }
        }
      }
      ul.iq-submenu-data {
        li {
          > a {
            padding-left: 3.125rem;
          }
          margin: 0;
        }
      }
    }
    li.active {
      background: rgba($primary, 0.1);
      > a {
        color: $primary;
        text-decoration: none;
      }
      a[aria-expanded="true"] {
        box-shadow: none;
      }
      .iq-submenu {
        li {
          > a {
            background: transparent;
          }
          &:last-child {
            > a {
              @if $enable-rounded == true {
                @include radius-mixin(5px 5px 0 0);
              }
            }
          }
          a[aria-expanded="true"] {
            box-shadow: none;
          }
          &.active {
            background-color: transparent;
            &a {
              color: $body-color;
            }
          }
        }
        li.active {
          > a {
            color: $primary;
            @if $enable-rounded == true {
              @include radius-mixin(0);
            }
          }
        }
        box-shadow: none;
      }
      ul.iq-submenu-data {
        li.active {
          > a {
            background: rgba($primary, 0.2);
            color: $primary;
            @if $enable-rounded == true {
              @include radius-mixin(0);
            }
          }
        }
      }
    }
    li.active-menu {
      background: rgba($primary, 0.2);
      > a {
        color: $primary;
        text-decoration: none;
      }
    }
    .iq-submenu {
      .iq-submenu-data {
        box-shadow: none;
      }
    }
  }
  position: relative;
}

body.sidebar-main {
  .iq-sidebar {
    .iq-menu {
      li {
        a[aria-expanded="false"].collapsed {
          @if $enable-rounded == true {
            @include radius-mixin($border-radius);
          }
        }
        a[aria-expanded="true"] {
          @if $enable-rounded == true {
            @include radius-mixin(5px 5px 0 0);
          }
        }
      }
    }
    .iq-sidebar-menu {
      .iq-menu {
        li {
          ul.iq-submenu-data {
            li {
              > a {
                padding-left: 1.25rem;
              }
            }
          }
        }
      }
    }
    &:hover {
      .iq-sidebar-menu {
        .iq-menu {
          li {
            ul.iq-submenu-data {
              li {
                > a {
                  padding-left: 1.25rem;
                }
              }
            }
          }
        }
      }
    }
  }
  #content-page {
    div > .dashboard-layout {
      margin-right: 1.25em;
      padding-bottom: 1.5em;
    }
  }
}
.sidebar-main {
  .iq-sidebar-menu {
    .iq-menu {
      li {
        ul {
          padding-left: 0;
        }
      }
    }
  }
}

.iq-top-navbar {
  .iq-navbar-custom {
    .iq-menu-bt-sidebar {
      .iq-menu-bt {
        display: block;
        margin: 0 0 0 1.25rem;
      }
    }
  }
}
body.sidebar-main {
  .iq-menu-bt-sidebar {
    .iq-menu-bt {
      @include opacity-mixin(1);
    }
  }
}
.iq-menu-bt {
  padding: 0.625rem 0.438rem;
  margin: 0 0.938rem;
  @if $enable-transitions == true {
    @include transition-mixin(all 0.3s ease-in-out);
  }
  @if $enable-rounded == true {
    @include radius-mixin($border-radius);
  }
}
.wrapper-menu {
  font-size: 1.25rem;
  text-align: center;
  line-height: 0.938rem;
  color: $body-color;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @if $enable-transitions == true {
    @include transition-mixin(all 0.3s ease-in-out);
  }
}

@media (min-width: 1500px) {
  body.sidebar-main {
    .iq-sidebar {
      // width: 80px;
      .iq-sidebar-menu {
        .iq-menu {
          li {
            a {
              // span {
              //   @include opacity-mixin(0);
              //   display: none;
              //   @if $enable-transitions == true {
              //     @include transition-mixin(all 0.3s ease-in-out);
              //   }
              // }
            }
          }
        }
      }
    }
    .iq-sidebar-logo {
      a {
        // span {
        //   @include opacity-mixin(0);
        //   display: none;
        //   @if $enable-transitions == true {
        //     @include transition-mixin(all 0.3s ease-in-out);
        //   }
        // }
      }
    }
    .iq-sidebar-menu {
      .iq-menu {
        li {
          a {
            .badge {
              @include opacity-mixin(0);
              display: none;
              @if $enable-transitions == true {
                @include transition-mixin(all 0.3s ease-in-out);
              }
            }
          }
        }
      }
    }

    #content-page {
      div > .dashboard-layout {
        margin-right: auto;
      }
    }
  }
  .sidebar-main {
    .iq-sidebar {
      .iq-submenu {
        li {
          a {
            font-size: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 1300px) and (max-width: 1499px) {
  // .iq-sidebar {
  //   width: 80px;
  // }
  body.sidebar-main {
    .iq-sidebar {
      width: 255px;
      .iq-sidebar-menu {
        .iq-menu {
          li {
            a {
              span {
                @include opacity-mixin(1);
                display: block;
                @if $enable-transitions == true {
                  @include transition-mixin(all 0.3s ease-in-out);
                }
              }
            }
          }
        }
      }
    }
    .iq-sidebar-logo {
      a {
        span {
          @include opacity-mixin(1);
          display: block;
          @if $enable-transitions == true {
            @include transition-mixin(all 0.3s ease-in-out);
          }
        }
      }
    }
    .iq-sidebar-menu {
      .iq-menu {
        li {
          a {
            .badge {
              @include opacity-mixin(1);
              display: block;
              @if $enable-transitions == true {
                @include transition-mixin(all 0.3s ease-in-out);
              }
            }
          }
        }
      }
    }
  }
  .iq-sidebar {
    .iq-sidebar-menu {
      .iq-menu {
        li {
          a {
            span {
              @include opacity-mixin(0);
              display: none;
              @if $enable-transitions == true {
                @include transition-mixin(all 0.3s ease-in-out);
              }
            }
          }
        }
      }
    }
    .iq-submenu {
      li {
        a {
          font-size: 0;
        }
      }
    }
  }
  .iq-sidebar-logo {
    a {
      span {
        @include opacity-mixin(0);
        display: none;
        @if $enable-transitions == true {
          @include transition-mixin(all 0.3s ease-in-out);
        }
      }
    }
  }
  .iq-sidebar-menu {
    .iq-menu {
      li {
        a {
          .badge {
            @include opacity-mixin(0);
            display: none;
            @if $enable-transitions == true {
              @include transition-mixin(all 0.3s ease-in-out);
            }
          }
        }
      }
    }
  }
  .sidebar-main {
    .iq-sidebar {
      .iq-submenu {
        li {
          a {
            font-size: $font-size-base;
          }
        }
      }
    }
  }
}
// body.sidebar-main {
//   .iq-sidebar.show {
//     left: 0;

//   }
// }

@media (max-width: 1000px) {
  .iq-sidebar {
    display: inline-block;
    z-index: 99;
    // left: -260px;

    transition: left 1s ease-in-out;
  }
  body.sidebar-main {
    .iq-sidebar {
      width: 220px;
      left: -260px;
      z-index: 999;
      position: absolute;
      top: 0;
      height: 100%;
      // display: none !important;
    }

    .iq-sidebar.show {
      left: 0;
    }
  }

  .user-name {
    display: none;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dashboard-layout {
    max-width: 100% !important;
  }

  // .logo {
  //   margin: 0 1.938rem !important;
  // }

  // .logo > svg > path {
  //   display: none;
  // }
}
