.dropdown-menu{
    text-align:right;
}

.dropdown-menu.dropdown-menu-right{
    .dropdown-item{
        i{
            float: right;
        }
    }
}