.sign-in-detail {
	color: $white;
	padding: 4rem;
	height: 100vh;
}
.sign-in-page {
	//background: $primary;
	//background: rgba($primary,80);
	//background: -moz-linear-gradient(-45deg, rgba($primary,80) 0%, #0076e5 100%);
	//background: -webkit-linear-gradient(-45deg, rgba($primary,80) 0%, #0076e5 100%);
	//background: linear-gradient(135deg, rgba($primary,80) 0%, #0076e5 100%);
	//background-image: url("https://app.ecampus.camp/v3/img/login-bg/bg-7.jpg");
	background: url("https://app.ecampus.camp/v3/img/login-bg/bg-7.jpg") center no-repeat;
	background-size: cover;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba($primary,80)', endColorstr='#0076e5', GradientType=1);
	position: relative;
	overflow: hidden;
	height: 100vh;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	.container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.sign-in-logo {
	display: inline-block;
	width: 100%;
	img {
		height: 3.125rem;
	}
}
.sign-in-from {
	padding: 0 3.75rem;
	margin-top: auto;
    width: 100%;
    margin-bottom: auto;
}
.sign-info {
	border-top: 1px solid rgba(0,0,0,0.1);
	margin-top: 1.5rem;
	padding-top: 1.5rem;
}
.iq-social-media {
	margin: 0;
	padding: 0;
	float: right;
	li {
		list-style: none;
		float: left;
		margin-right: 0.625rem;
		&:last-child {
			margin-right: 0;
		}
		a {
			height: 1.875rem;
			width: 1.875rem;
			text-align: center;
			font-size: 1.125rem;
			line-height: 1.875rem;
			display: inline-block;
			background:rgba($primary,0.2);
			color: $primary !important;
            @if $enable-rounded == true {
                @include radius-mixin($border-radius);
            }
			&:hover {
				text-decoration: none;
			}
		}
	}
}
#container-inside {
	position: relative;
	min-width: 60rem;
	max-width: 80rem;
	height: auto;
	min-height: 100%;
	margin: 0rem auto;
	padding: 0rem auto;
	overflow: visible;
}
#circle-small {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0s;
	position: absolute;
	top: 12.5rem;
	left: -9.375rem;
	background: $white;
	width: 18.75rem;
	height: 18.75rem;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-pill);
	}
	opacity: 0.4;
}
#circle-medium {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0.3s;
	position: absolute;
	top: 3.125rem;
	left: -18.75rem;
	background: $white;
	width: 37.5rem;
	height: 37.5rem;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-pill);
	}
	opacity: 0.3;
}
#circle-large {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0.6s;
	position: absolute;
	top: -6.25rem;
	left: -28.125rem;
	background: $white;
	width: 56.25rem;
	height: 56.25rem;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-pill);
	}
	opacity: 0.2;
}
#circle-xlarge {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 0.9s;
	position: absolute;
	top: -15.625rem;
	left: -37.5rem;
	background: $white;
	width: 75rem;
	height: 75rem;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-pill);
	}
	opacity: 0.1;
}
#circle-xxlarge {
	-webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation: circle-small-scale 3s ease-in-out infinite alternate;
	animation-timing-function: cubic-bezier(.6, 0, .4, 1);
	animation-delay: 1.2s;
	position: absolute;
	top: -25rem;
	left: -46.875rem;
	background: $white;
	width: 93.75rem;
	height: 93.75rem;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius-pill);
	}
	opacity: 0.05;
}

@include media-breakpoint-down(lg) {
    .sign-in-page {
        overflow-y: scroll;
    }
    .sign-in-detail {
        padding: 2rem 1.25rem;
    }
}
@include media-breakpoint-down(md) {
    .sign-in-detail {
        padding: 3.125rem 1.25rem;
    }
}

@-webkit-keyframes circle-small-scale {
    0% { -webkit-transform: scale(1.0); }
    100% { -webkit-transform: scale(1.1); }
}
@keyframes circle-small-scale {
    0% { transform: scale(1.0); }
    100% { transform: scale(1.1); }
}

.hide-scrollbar::-webkit-scrollbar{
	display: none;
}

.hide-scrollbar{
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.sign-in-row {
	--bs-gutter-x: 0 !important;
}

.border-active {
	border-color: #7F7F7F;
}

.swiper-pagination{
	display: flex;
	justify-content: center;
	gap: 0.5rem;
}

.swiper-pagination-bullet{
	background-color: #F1B19B;
	width: 0.625rem;
	height: 0.625rem;
	border-radius: 0.625rem;
	display: block;
}

.swiper-pagination-bullet-active{
	background-color: white;
	width: 1.5rem;
	height: 0.625rem;
	border-radius: 0.625rem;
	display: block;
}

.auth-slider-img{
	width: 633px;

	@include media-breakpoint-down(xl) {
		width: 500px;
	}

	@include media-breakpoint-down(lg) {
		width: 300px;
	}
}

.auth-slider-header-text{
	font-size: 48px;

	@include media-breakpoint-down(xl) {
		font-size: 36px;
	}

	@include media-breakpoint-down(lg) {
		font-size: 24px;
	}
}

.auth-slider-details-text{
	font-size: 20px;
	padding: 0 6rem;

	@include media-breakpoint-down(xl) {
		font-size: 18px;
		padding: 0 3rem;
	}

	@include media-breakpoint-down(lg) {
		font-size: 16px;
		padding: 0 1rem;
	}
}