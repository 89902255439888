.scroller {
  overflow-y: auto;
  scrollbar-color: $primary rgba($primary, 0.2);
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba($primary, 0.2);
  }
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  @if $enable-rounded == true {
    @include radius-mixin(20px);
  }
}
::-webkit-scrollbar-track {
  background: $border-color;
  @if $enable-rounded == true {
    @include radius-mixin(20px);
  }
}
::-webkit-scrollbar-thumb {
  background: $secondary;
  @if $enable-rounded == true {
    @include radius-mixin(20px);
  }
  &:hover {
    background: $dark;
    @if $enable-rounded == true {
      @include radius-mixin(20px);
    }
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 255px 1fr !important;
  overflow: hidden;
  height: 100vh;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr !important;
  }

  // .iq-sidebar {
  //   @media (max-width: 1000px) {
  //     display: none !important;
  //   }
  // }
}

 .scroller-hide {
   overflow: scroll;
   overflow-x: hidden;
   -ms-overflow-style: none; /* Internet Explorer 10+ */
   scrollbar-width: none; /* Firefox */
 }
 .scroller-hide::-webkit-scrollbar {
   display: none; /* Safari and Chrome */
 }

.content-page {
  //overflow: hidden;
  padding: 1rem 1rem 0;
  // height: 90vh;
  @if $enable-transitions == true {
    @include transition-mixin(all 0.3s ease-in-out);
  }
  // overflow: scroll;
  // overflow-x: hidden;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none; /* Firefox */

  // @media (min-width: 1450px) {
  //   height: 92vh;
  // }
}
.content-page::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.content-page {
  -ms-overflow-style: none;  /* Internet Explorer and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content-card,
.category-card {
  padding: 25px 0px 25px 25px;
  overflow: hidden;

  .cards-container {
    overflow-x: auto;
    .category-btn {
      width: 144.44px;
      height: 50px;
      padding: 0px 9.241px 0px 10px;
      color: #3a3a3a;
      font-size: 12px;
      font-weight: 500;
      border-radius: 10px;
      background: rgba(234, 152, 63, 0.2);
    }
  }
}

.content-card {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  //background: rgba(246, 246, 246, 0.5);
  background: #F5F5F5;

  .page-title {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .tab-content-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 36em;
  }
}

.content-point-card {
  border-radius: 5px;
  width: 73px;
  height: 73px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.categories-btn {
  border-radius: 10px;
  background: rgba(26, 26, 26, 0.65);
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  width: 144px;
  height: 70px;

  &:hover {
    color: #fff;
  }
}
.user-tabing {
  .course-tab {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 600;

    li {
      a {
        color: #1e1e1e;
      }
      a.active {
        background-color: unset !important;
        color: inherit !important;
        background-color: none;
        border-bottom: 7px solid #bb1c17;
        border-radius: 2.5px;
      }
    }
  }
}

.course-sub-details {
  @media (max-width: 300px) {
    flex-direction: column;
  }
}

/*file*/
.list-user-action a {
  font-size: 1rem;
  height: 1.563rem;
  width: 1.563rem;
  text-align: center;
  line-height: 1.563rem;
  margin: 0 0.188rem;
  display: inline-block;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius);
  }
}
.user-list-files a {
  padding: 0.625rem 0.938rem;
  margin: 0 0 0 0.625rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius);
  }
  line-height: normal;
}

@include media-breakpoint-up(xl) {
  //.container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 62.5rem; }
  //.container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 75.5rem; }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    // max-width: 70rem;
  }
}

@media (min-width: 1450px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}

@include media-breakpoint-down(md) {
  .iq-birthday-block .d-flex {
    display: block !important;
    text-align: center;
    margin: 0.625rem 0;
  }
}
@include media-breakpoint-down(sm) {
  .toast {
    width: auto;
  }
}

.h45 {
  height: 45px;
}

.min-h45 {
  min-height: 45px;
}

.max-h45 {
  max-height: 45px;
}

.h66 {
  height: 66px;
}

.min-h66 {
  max-height: 66px;
}

.max-h66 {
  max-height: 66px;
}

.h-80 {
  height: 80%;
}

.vh-60 {
  height: 60vh;
}

.vh-80 {
  height: 80vh;
}

.min-h-80 {
  min-height: 80%;
}

.line-height-16 {
  line-height: 1.2em;
}

.fs-7 {
  font-size: 0.75rem;
}

.fs-8 {
  font-size: 0.5rem;
}
