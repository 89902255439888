.card {
  border-radius: 10px !important;
  // box-shadow: $card-box-shadow;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius);
  }
  .card-header {
    margin-bottom: 0;
    -ms-flex-align: center !important;
    align-items: center !important;
    border: 0;
    border-bottom: 1px solid $border-color;
    .header-title {
      .card-title {
        margin-bottom: 0;
      }
    }
  }
  &.card-transparent {
    background: transparent;
    box-shadow: none;
    border: none;

    .card-header {
      background: transparent;
      border: none;
    }
  }

  &.test-card {
    cursor: pointer;
  }

  &.course-card {
    height: 7em;
    max-height: 7em;
    cursor: pointer;

    @media (max-width: 430px) {
      height: auto;
      max-height: fit-content;
    }
  }

  &.view-course-card {
    height: 64px;
    max-height: 64px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(246, 246, 246, 0.5);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

    h6 {
      font-size: 12px;
    }
  }

  &.course-outline {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(246, 246, 246, 0.5);
    height: 90em;
    overflow: scroll;
    .topic-line {
      background-color: red !important;
      width: 1px;
      height: 54px;
      z-index: 99;
      position: relative;
      top: 20px;
      right: 10.6px;
    }

    * {
      background: rgba(246, 246, 246, 0.5) !important;
      width: inherit;
    }
  }

  &.discussCard {
    height: 67.5em;
    max-height: 67.5em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(246, 246, 246, 0.5);
    box-shadow: none;
    overflow: hidden;

    .comment-container {
      height: 64em;
      .dropstart .dropdown-toggle::before {
        display: none;
      }
    }
  }

  .replies-container {
    .dropstart .dropdown-toggle::before {
      display: none;
    }
  }

  .reply {
    .dropstart .dropdown-toggle::before {
      display: none;
    }
  }
}

.add-comment {
  height: 78px;
  background-color: #fff;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  #post {
    background: #f5f5f5;
    border-radius: 10px;
    // width: 372px;
    height: 46px;
    border: unset;
  }

  .profile-picture {
    width: 35px;
    height: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.card-header-toolbar {
  .nav-item {
    a {
      color: $body-color;
      padding: 0.313rem 0.625rem;
      font-size: $font-size-base;
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}
.card-icon {
  height: 3.75rem;
  width: 3.75rem;
  display: inline-block;
  line-height: 3.75rem;
  text-align: center;
  font-size: 1.375rem;
}
.card-post-toolbar {
  .dropdown-toggle {
    i {
      font-size: 1.375rem;
      line-height: normal;
      color: $body-color;
    }
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.card-block.card-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-shadow: (0px 1px 6px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  // drop-shadow: (0px 1px 6px rgba(0, 0, 0, 0.1));
}
.card-block.card-height-half {
  height: calc(50% - 1rem);
}
.card-block.card-height {
  height: calc(100% - 1rem);
}
.card-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

// .content-providers-main-container {
//   display: grid;
//   grid-template-columns: repeat(6, 150px);
//   grid-gap: 20px;
// }

.content-providers-container,
.content-card-container {
  .image-block {
    height: 200px;
    //height: auto;
    max-height: 200px !important;
    text-align: center;

    div,
    img {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      object-position: center;
      object-fit: fill;
    }
  }
}

.content-providers-main-container {
  @media (min-width: 992px) and (max-width: 1250px) {
    @include make-col(3);
  }

  @media (min-width: 700px) and (max-width: 900px) {
    @include make-col(4);
  }

  @media (min-width: 576px) and (max-width: 700px) {
    @include make-col(6);
  }

  .content-providers-container {
    height: 300px;
    max-height: 300px;

    @media (max-width: 575px) {
      height: auto;
      max-height: unset;
    }
  }
}

.course-card-hero {
  border-radius: 10px;
  background: #fbebda;
  height: 240px;
  max-height: 240px;
  overflow: hidden;
  box-shadow: unset !important;

  img {
    width: 110px;
  }
}

.interest {
  background: linear-gradient(
      180deg,
      rgba(26, 26, 26, 0.65) 24%,
      rgba(26, 26, 26, 0.65) 58%
    ),
    url("https://t.ly/w6Ps5");
  background-position: center;
  height: 70px;
  background-repeat: no-repeat;
  background-size: cover;

  h5 {
    font-size: 13px;
    font-weight: 700;
    text-align: center;
  }
}

.course-card-details-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  .card {
    box-shadow: unset !important;
    border-radius: 10px;
    border: 1px solid #d3dbe0;
    background: #fff;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 390px) {
    grid-template-columns: 1fr;
  }
}

.test-card-details-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;

  .card {
    box-shadow: unset !important;
    border-radius: 10px;
    border: 1px solid #d3dbe0;
    background: #fff;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 390px) {
    grid-template-columns: 1fr;
  }
}
