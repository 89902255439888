.iq-icons-list {
    line-height: 3.125rem;
    margin-bottom: 0.938rem;
    display: inline-block;
    
    i {
        text-align: center;
        vertical-align: middle;
        font-size: 1.125rem;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        margin-right: 0.625rem;
        display: inline-block;
    }
}