.main-temp{
    h1.display-1{
        text-align: center;
    }
    span{
        text-align: center;
    }

}
ul.weather-list{
    li{
       h6{
            text-align: center;
        }
    }
}
ul.iq-week-data{
    p{
        text-align: center;
    }
}